import { useState } from 'react';
import { Scrollbar } from 'smooth-scrollbar/scrollbar';
import './App.css';
import About from './components/About';
import BodySection from './components/BodySection';
import Contact from './components/Contact';
import Header from './components/Header';
import Projects from './components/Projects';

function App() {
  const [toggler,settoggler]=useState(true)
  const getData = (data) => {
    settoggler(data)
  }
  return (

    <div className={toggler?'':'max-h-[100vh] overflow-hidden' + " lg:max-h-[100%] lg:overflow-auto"} id='home'>
        
        <Header getval={getData}/>
        <div className={toggler?'':'border-none pointer-events-none transform scale-[1.02] blur-sm ease-in-out duration-400 bg-[#1B1F24]'+" lg:blur-none lg:transform lg:scale-[1] ease-in-out duration-100"}> <BodySection /> </div>
        <About />
        <Projects />
        <Contact />


    </div>

  );
}

export default App;
