import React, { useState } from 'react'
import { Link } from 'react-scroll'
import file from '../assets/Resume.pdf'
export default function Header(props) {
    const [click, setClick] = useState(false);
    const toggledclass = click ? "w-[60%]" : "w-[0] "
    const navToggled = click ? "flex" : "hidden"
    const navBurgerToggler1 = click ? "mb-0 rotate-45 " : "mb-2"
    const navBurgerToggler2 = click ? "mb-0 hidden" : "mb-2"
    const navBurgerToggler3 = click ? "mb-0 w-[3rem] rotate-[-45deg] translate-y-[-3px] " : "mb-2 w-[2rem]"


    const clickedFunc = () => {
        setClick(!click)
        props.getval(click)

    }

    return (
        <>
            <nav className="navbar h-[10vh] navbar-expand-lg w-100  bg-[#1B1F24] text-white">
                <div className="container-fluid px-[4%] lg:px-[10%] ">
                    <div className="navbar-brand  hover:text-[#64ffda] cursor-pointer tracking-wider font-bold text-[#64ffda]">
                        Prajwal
                    </div>
                    <div className="hamburger ml-auto block lg:hidden  cursor-pointer absolute right-5" style={{ "zIndex": "12" }} onClick={clickedFunc} >
                        <div className={"ease-in-out duration-150 line1 min-h-[0.3rem] w-[3rem] bg-white " + navBurgerToggler1}></div>
                        <div className={"ease-in-out duration-150 line2 min-h-[0.3rem] w-[2.5rem] bg-white " + navBurgerToggler2}></div>
                        <div className={"ease-in-out duration-150 line3 min-h-[0.3rem]  bg-white " + navBurgerToggler3}></div>

                    </div>
                    <div className="collapse navbar-collapse justify-center" id="navbarSupportedContent">
                        <ul className={"navbar-nav d-flex justify-between w-[90%] xl:w-[40%]  "}>
                            <li className='font-grostek  nav-item font-medium text-2xl'>
                                <Link  className="cursor-pointer hover:text-white" activeClass="active" to="home" spy={true} smooth={true} hashSpy={true} offset={10} duration={500} delay={200} isDynamic={true} >Home</Link>

                            </li>
                            <li className='font-grostek font-medium nav-item text-2xl'>

                                <Link className="cursor-pointer hover:text-white" to="about" spy={true} smooth={true} hashSpy={true} offset={50} duration={500} delay={200} isDynamic={true}  >About</Link>
                            </li>
                            <li className='font-grostek nav-item font-medium text-2xl'>

                                <Link to="project" className='cursor-pointer hover:text-white' spy={true} smooth={true} hashSpy={true} offset={50} duration={500} delay={200} isDynamic={true} >Activities</Link>

                            </li>
                            <li className='font-grostek font-medium nav-item text-2xl '>

                                <Link to="contact" className='cursor-pointer hover:text-white' spy={true} smooth={true} hashSpy={true} offset={50} duration={500} delay={200} isDynamic={true} >Contact</Link>

                            </li>
                        </ul>
                    </div>
                    <div className="contact-button hidden lg:block">
                        <a href={file}><button className="btn btn-white-outline ml-50 font-grostesk text-xl  text-white border border-white resumeHeader ">
                            Resume
                        </button>
                        </a>
                    </div>
                </div>
            </nav>
            <div className={"mobile-navbar min-h-[100vh] absolute block lg:hidden ease-in-out duration-200 bg-[rgb(49,57,65)] z-10 top-0 right-0 " + toggledclass}>
                <div className={"justify-center h-[100vh] flex align-middle content-center pt-[5rem] " + navToggled}>
                    <ul className="navbar-nav  flex flex-col align-middle h-50 justify-between">
                        <li className="nav-items text-3xl font-grostesk font-bold text-white">
                                <Link className="cursor-pointer hover:text-white" to="home" spy={true} smooth={true} hashSpy={true} offset={50} duration={500} delay={200} isDynamic={true} onClick={clickedFunc} >Home</Link>
                        </li>
                        <li className="nav-items text-3xl font-grostesk font-bold text-white">
                                <Link className="cursor-pointer hover:text-white" to="about" spy={true} smooth={true} hashSpy={true} offset={50} duration={500} delay={200} isDynamic={true} onClick={clickedFunc} >About</Link>
                        </li>
                        <li className="nav-items text-3xl font-grostesk font-bold text-white">
                                <Link className="cursor-pointer hover:text-white" to="project" spy={true} smooth={true} hashSpy={true} offset={50} duration={500} delay={200} isDynamic={true} onClick={clickedFunc} >Activities</Link>
                        </li>
                        <li className="nav-items text-3xl font-grostesk font-bold text-white">
                                <Link className="cursor-pointer hover:text-white" to="contact" spy={true} smooth={true} hashSpy={true} offset={50} duration={500} delay={200} isDynamic={true} onClick={clickedFunc} >Contact</Link>
                        </li>
                        <li className="nav-items text-3xl font-grostesk font-bold text-white">

                            <a href={file}><button className="btn btn-white-outline ml-50 font-grostesk text-xl  text-white border border-white ">
                                Resume
                            </button>
                            </a>
                        </li>
                    </ul>
                </div>

            </div>
        </>
    )
}