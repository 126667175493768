import React from 'react'
import { Link } from 'react-scroll';
import TypeWriterEffect from 'react-typewriter-effect';

function BodySection(props) {
    const intro = "I’m a passionate programmer and engineer specializing in building (and occasionally developing android applications) exceptional digital experiences. Currently, I’m focused on bulding amazing web experiences."
    const introEd = "I'm a Computer Engineering student and passionate programmer learning and exploring various technologies and building amazing experiences "
    return (
        <>
            <div className={"container-fluid  min-h-[90vh] lg:min-h-[100vh]  pt-40 bg-[#1B1F24] "}  >
                <div className="row">
                    <div className="col-lg-1 hidden pl-3 lg:flex flex-col min-h-[78vh]  justify-evenly">
                        <div className="facebook">

                            <i className="fa-brands fa-facebook text-2xl text-[#64ffda] ease-in-out duration-200 hover:translate-y-[-3px] cursor-pointer" style={{
                                "hover": {
                                    "textShadow": "0 0 4px #64ffda"
                                }
                            }}></i>
                        </div>
                        <div className="instagram">

                            <i className="fa-brands fa-instagram text-2xl text-[#64ffda] ease-in-out duration-200 hover:translate-y-[-3px] cursor-pointer"></i>
                        </div>
                        <div className="linkedin">
                            <i className="fa-brands fa-linkedin-in text-2xl text-[#64ffda] ease-in-out duration-200 hover:translate-y-[-3px] cursor-pointer"></i>
                        </div>
                        <div className="github">
                            <i className="fa-brands fa-github text-2xl text-[#64ffda] ease-in-out duration-200 hover:translate-y-[-3px] cursor-pointer"></i>
                        </div>
                        <div className="twitter">
                            <i className="fa-brands fa-twitter text-2xl text-[#64ffda] ease-in-out duration-200 hover:translate-y-[-3px] cursor-pointer"></i>
                        </div>

                        <div className="line min-h-[38vh] w-[2px] mt-5 ml-[15px] bg-white"> </div>
                    </div>
                    <div className="col-lg-10 block  min-h-30 px-[4%] lg:px-[2%]">
                        <div className='max-w-[10rem] bg-white h-1 hidden lg:block mb-10'>
                        </div>
                        <p className='font-playfair font-bold text-3xl md:text-5xl lg:text-6xl text-white'>
                            I’m Prajwal,<br />a Web Developer
                        </p>
                        <div className="font-playfair font-light text-white text-md md:text-xl lg:text-2xl">
                            <p className='w-[20rem] md:w-[30rem]'>

                                <TypeWriterEffect
                                    textStyle={{ fontFamily: 'Red Hat Display' }}
                                    startDelay={100}
                                    cursorColor="black"
                                    typeSpeed={50}
                                    multiText={[
                                        introEd,
                                        intro,

                                    ]}
                                    multiTextDelay={1000}
                                />
                            </p>

                            <Link activeClass="active" to="about" spy={true} smooth={true} hashSpy={true} offset={50} duration={500} delay={200} >
                                <div className="downButton bg-[#186AE4] rounded-full w-[4rem] h-[4rem] lg:w-[5rem] lg:h-[5rem] mt-10 cursor-pointer">
                                    <i className="fa-solid fa-arrow-down text-3xl pl-5 pt-3 lg:text-4xl lg:pl-6 md:pt-6 animate-bounce"></i>
                                </div>

                            </Link>
                            <div className="hire-button pt-10 ">
                            <a href="mailto:prajwalgaire617@gmail.com"><button className="w-45 border-1 border w-[15rem] rounded-md bodyButton font-grostesk">
                                    Let's work together
                                </button>
                            </a>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-1  hidden lg:block min-h-40 px-[4%] right-3">
                    <a href="mailto:prajwalgaire617@gmail.com"><p className="email  font-mono text-xl pt-39 font-light text-white " style={{ 'writingMode': 'vertical-rl' }}>
                            prajwalgaire617@gmail.com
                        </p>
                    </a>
                        <section className="line min-h-[38vh] w-[2px] mt-5 ml-[15px] bg-white"> </section>

                    </div>

                </div>

            </div>
        </>
    )
}

export default BodySection