import React from 'react'
import Image from '../assets/picture.jpg'

export default function About() {
  return (
    <>
      <div className="bg-[#22282F] min-h-[100vh] px-[3%] lg:px-[9%] pt-[5%]" id='about'>
        <div className="title flex flex-auto mb-[10%]">
          <h1 className='text-white text-6xl m-auto font-trillium font-bold'>About me</h1>
        </div>
        <div className="row container">
          <div className="col-lg-6 col-sm-12 col-md-12" >
            <p className='text-white text-xl lg:text-2xl text-left font-playfair'>
              Hello! My name is Prajwal and I enjoy creating things that live on the internet.<br />
              I am currently studying bachelors in Computer Science and Engineering at Kathmandu University. I am web developer and expanding my knowldeges in various other domains as well.<br />
              <br/>
              Here are a few technologies I’ve been working with recently: <br /> <br/>
              <ul className='grid grid-cols-2 gap-2'>
                <li><i className="fa-solid fa-circle-check text-xl text-[#6DF2D5]"></i> Javascript</li>
                <li><i className="fa-solid fa-circle-check text-xl text-[#6DF2D5]"></i> React</li>
                <li><i className="fa-solid fa-circle-check text-xl text-[#6DF2D5]"></i> Next</li>
                <li><i className="fa-solid fa-circle-check text-xl text-[#6DF2D5]"></i> Node Js</li>
                <li><i className="fa-solid fa-circle-check text-xl text-[#6DF2D5]"></i> C/C++</li>
                <li><i className="fa-solid fa-circle-check text-xl text-[#6DF2D5]"></i> QT</li>
                <li><i className="fa-solid fa-circle-check text-xl text-[#6DF2D5]"></i> PHP</li>
              </ul>
            </p>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-12 py-[10%] lg:pt-0 flex justify-center">
            <img src={Image} alt='image of prajwal gaire' className=' w-[20rem] h-[20rem] saturate-[30%]  object-cover rounded-lg ease-in-out duration-100 drop-shadow-[20px_10px_4px_#1B1F24] hover:drop-shadow-[30px_10px_4px_#1B1F24] hover:translate-y-[-5px] hover:saturate-[1]' />
          </div>
        </div>
      </div>
    </>
  )
}
