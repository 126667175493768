import React from 'react'

function Contact() {
    return (
        <div className='min-h-[50vh] bg-[#22282F] px-[5%] pt-[10%] pb-[2%]' id='contact'>
            <div className="container border-b-[1px] border-[#BFC6D0] " >
                <div className="row">
                    <div className="col-lg-12 col-xl-6 col-sm-12 col-md-12 flex">

                        <span className='text-2xl lg:text-3xl text-white font-grostesk pt-[1%] ml-[4%]'>
                            Prajwal Gaire<br />
                            <p className=' text-lg md:text-xl lg:text-2xl font-grostesk text-[#8895AA]  pt-3 '>
                                Learn Create Contribute
                            </p>
                            <div className="contactIcons pt-[2rem] pl-0">
                                <a href="https://www.instagram.com/gaire.prajwal/" target="_blank" rel="noopener noreferrer"><i className='fa-brands fa-instagram text-2xl lg:text-3xl cursor-pointer text-white pr-5 ease-in-out duration-200 hover:translate-y-[-3px]'></i></a>
                                <a href="https://www.facebook.com/prajwal.gaire/" target="_blank" rel="noopener noreferrer"><i className='fa-brands fa-facebook text-2xl lg:text-3xl cursor-pointer text-white pr-5 ease-in-out duration-200 hover:translate-y-[-3px]'></i></a>
                                <a href="https://twitter.com/prajwal_gaire" target="_blank" rel="noopener noreferrer"><i className='fa-brands fa-twitter text-2xl lg:text-3xl cursor-pointer text-white pr-5  ease-in-out duration-200 hover:translate-y-[-3px]'></i></a>
                                <a href="https://github.com/prajwalgaire617" target="_blank" rel="noopener noreferrer"><i className='fa-brands fa-github text-2xl lg:text-3xl cursor-pointer text-white pr-5 ease-in-out duration-200 hover:translate-y-[-3px]'></i></a>
                                <a href="https://www.linkedin.com/in/prajwal-gaire-42b46420a/" target="_blank" rel="noopener noreferrer"><i className='fa-brands fa-linkedin-in  text-2xl lg:text-3xl cursor-pointer text-white pr-5 ease-in-out duration-200 hover:translate-y-[-3px]'></i></a>
                            </div>
                        </span>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 pl-[1rem] lg:pl-20 pt-20 xl:pt-0 font-bold">
                        <h1 className="title font-grostesk text-3xl lg:text-5xl text-white">
                            Get in touch  <i className=" ease-in-out duration-200 fa-solid fa-arrow-right text-4xl text-[#186AE4] cursor-pointer hover:translate-x-2 "></i>
                        </h1>
                        <div className="container mt-[5%]">

                            <div className="contact row">
                                <div className="email col-sm-12 col-md-6 col-lg-6 pt-[3rem] md:pt-0 mb-10">
                                    <h1 className='font-grostesk text-[#C3CAD5] text-lg lg:text-xl'>
                                        Email:
                                    </h1>
                                    <a href="mailto:prajwalgaire617@gmail.com" className=' text-[#C3CAD5] hover:text-[#C3CAD5]'>
                                        <div className="emailaddress mt-4">
                                            <p className='font-bold font-grostesk hover:text-[#C3CAD5]  text-xl lg:text-lg'> prajwalgaire617@gmail.com </p>
                                            
                                        </div>
                                    </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>

            </div>
            <p className='text-white text-center flex justify-center pt-7'> &copy; Copyright 2022 Prajwal Gaire</p>
        </div>
    )
}

export default Contact
