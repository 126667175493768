import React from 'react'

export default function CardProject(props) {
  
  return (
    <div className='card flex flex-col m-2 minh-[40vh] bg-[rgb(40,47,60)]  shadow-sm  duration-100 ease-in-out hover:translate-y-[-3px] hover:shadow-lg text-white'>
        <div className="icons flex justify-between h-[3vh] px-3 py-3">
            <i className={"fa-solid fa-"+props.iconTitle+" text-[#6DF2D5] text-4xl "}></i>
            <h1 className="text-center content-center text-xl font-bold font-grostesk">{props.title}</h1>
            <div className=" flex justify-between w-[20%]">
                <a href={props.link} target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-arrow-up-right-from-square text-3xl text-[#6DF2D5]"></i></a>
                <i className={"fa-brands fa-"+props.icon+" text-[#6DF2D5] text-3xl"}></i>
            </div>
        </div>
        <div className="texts mt-4 px-3 py-4 content-center align-middle">
          {props.content}
        </div>

    </div>
  )
}
