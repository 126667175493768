import React from 'react'
import CardProject from './CardProject';

const blog1="Introduction The DOM (Page Object Model) is a programming interface that allows programs and scripts to dynamically access and update the content of a document, as well as create, alter, and delete elements...."
const blog1Title="DOM"
const link1="https://medium.com/@prajwalgaire617/document-object-model-dom-15f9400b8e53"


const blog2Title="Virtualization"
const blog2="Virtualization is a technique that allows several organizations or tenants to share a single physical instance of an application or resource (customers). It accomplishes this by giving a logical name to a physical resource and .... "
const link2="https://dev.to/prajwalgaire/virtualization-in-cloud-4a10"

const blog3Title="Cloud Computing"
const blog3="Cloud computing is a model for enabling universal, convenient, on-demand network access to a shared pool of configurable computing resources (e.g., networks, servers, storage, applications, and services) that ..."
const link3="https://dev.to/prajwalgaire/cloud-computing-3l68"

function Projects() {
  return (

    <div className="projects bg-[#1B1F24] flex justify-center min-h-[90vh] flex-col" id='project'>
      <div className='title text-white font-grostesk font-bold text-4xl my-10 flex justify-center'>My Recent Works </div>

      <div className="projects container bg-[#1B1F24] ">
        <div className="row ">
          <div className="col-sm-12 col-md-6 col-lg-4"><CardProject icon="medium" iconTitle="blog" title={blog1Title} content={blog1} link={link1}/></div>
          <div className="col-sm-12 col-md-6 col-lg-4"><CardProject icon="dev" iconTitle="blog" title={blog2Title} content={blog2} link={link2} /></div>
          <div className="col-sm-12 col-md-6 col-lg-4"><CardProject icon="dev" iconTitle="blog" title={blog3Title} content={blog3} link={link3} /></div>
        </div>
      </div>

      {/* <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4"> <CardSlider /></div>
          <div className="col-sm-12 col-md-6 col-lg-4"> <CardSlider /></div>
          <div className="col-sm-12 col-md-6 col-lg-4"> <CardSlider /></div>
          <div className="col-sm-12 col-md-6 col-lg-4"> <CardSlider /></div>
          <div className="col-sm-12 col-md-6 col-lg-4"> <CardSlider /> </div>
          <div className="col-sm-12 col-md-6 col-lg-4"> <CardSlider /> </div>
        </div>
      </div>
  */}
    </div>
  )
}

export default Projects;